<template>
  <div class="page">
    <bm-cardTabList
      :list="[{ name: '编辑资料', path: '/account/profile' }, { name: '修改密码' }]"
      current="修改密码"
    ></bm-cardTabList>
    <el-card header="修改密码" style="width:800px">
      <el-form label-width="120px" :model="form" ref="form">
        <el-form-item label="ID">
          <el-input v-model.trim="form.id" disabled style="width:200px"></el-input>
        </el-form-item>
        <el-form-item label="新密码">
          <el-input type="password" v-model.trim="form.password"></el-input>
        </el-form-item>
        <el-form-item label="重复密码">
          <el-input type="password" v-model.trim="form.passwordConfirm"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" style="width:200px" @click="handleSubmit">提交</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  data: () => ({
    form: {
      id: 0,
      password: '',
      passwordConfirm: ''
    }
  }),
  computed: {
    ...mapGetters({ userInfo: 'user/userInfo' })
  },
  watch: {},
  methods: {
    handleSubmit() {
      if (!this.form.id) {
        this.$message.error('参数错误')
      } else if (!this.form.password || !this.form.passwordConfirm) {
        this.$message.error('密码不能为空')
      } else if (this.form.password !== this.form.passwordConfirm) {
        this.$message.error('两次输入的密码不一致')
      } else {
        this.$http
          .put('/boom-center-admin-service/sysAdmin/sysUser/updatePwd', this.form)
          .then(res => {
            this.$message.success('修改密码成功')
            this.$router.push('/')
          })
          .catch(err => {
            this.$message.error(err.msg || '操作失败')
          })
      }
    }
  },
  created() {},
  mounted() {
    this.form.id = this.userInfo.id
  }
}
</script>

<style lang="less" scoped>
.userAvatar {
  width: 80px;
  height: 80px;
}
.uploadIcon {
  width: 80px;
  height: 80px;
  border: 1px dashed @color-border;
  .flexCenter;
  font-size: 26px;
  cursor: pointer;
  &:hover {
    border: 1px dashed @color-primary-select;
  }
}
</style>
